<template>
  <div>
    <div class="message is-dark">
      <div class="message-body has-padding-75">
        <apart>
          <p>Add a new site.</p>
          <button
            class="button is-dark is-outlined is-rounded"
            @click="addSite"
          >
            Add new site
          </button>
        </apart>
      </div>
    </div>

    <b-table
      :data="allSites"
      :mobile-cards="true"
      hoverable
      striped
      class="has-vertical-align-middle"
    >
      <template slot="empty">
        <no-results
          :cta="{ label: 'Add new site' }"
          icon="plus-circle"
          title="No sites"
          message="There are no sites to show."
          @click="addSite"
        />
      </template>

      <template slot-scope="props">
        <b-table-column label width="1">
          <status-label
            :status="props.row.isSubscribed ? 'subscribed' : 'unsubscribed'"
          />
        </b-table-column>

        <b-table-column label="Url" width="1" class="has-white-space-nowrap">
          <router-link
            :to="{ path: `/sites/${props.row._id}` }"
            class="has-text-dark"
          >
            <u>{{ props.row.fqdn }}</u>
          </router-link>
        </b-table-column>

        <b-table-column label="Users"
          >{{ getUsersCount(props.row) }} users</b-table-column
        >

        <b-table-column label="Created" width="200">
          <span>{{ $moment(props.row.dateCreated.toDate()).from() }}</span>
        </b-table-column>

        <b-table-column width="40">
          <b-dropdown position="is-bottom-left" class="has-text-left">
            <a slot="trigger" class="has-text-dark">
              <b-icon icon="cog" />
            </a>

            <b-dropdown-item
              v-if="props.row.isSubscribed"
              @click="manageClientsOfSite(props.row)"
              >Manage clients</b-dropdown-item
            >
            <b-dropdown-item v-else @click="subscribe(props.row)"
              >Subscribe</b-dropdown-item
            >
          </b-dropdown>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ResellerSitesTable",
  data() {
    return {
      pageLoading: true,
      filter: ""
    };
  },
  computed: {
    ...mapState({
      observingUserSites: state => state.sites.observingUserSites,
      sites: state => state.sites.observed
    }),
    user() {
      return this.$store.getters["user/user"]();
    },
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    reseller() {
      return this.$store.getters["reseller/reseller"](this.user.resellerId);
    },
    allSites() {
      return this.$_(this.sites)
        .filter(site => site.owners.includes(this.userId))
        .toArray()
        .value();
    }
  },
  methods: {
    getUsersCount(site) {
      return this.$_.difference(site.users, site.owners).length;
    },
    addSite() {
      const modal = this.$modal.open({
        component: () => import("@shared/sites/_addSiteModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: [],
        events: {
          success: siteId => {
            modal.close();
            this.$router.push(`/sites/${siteId}`);
          }
        },
        props: {
          parent: this
        }
      });
    },
    manageClientsOfSite(site) {
      this.$modal.open({
        component: () => import("@shared/reseller/_manageClientsOfSiteModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: site._id,
          resellerId: this.reseller._id
        }
      });
    },
    subscribe(site) {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradeModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          fqdn: site.fqdn,
          siteId: site._id,
          userId: site.authorId
        }
      });
    }
  }
};
</script>
